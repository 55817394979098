.StatPresenter {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  height: 14rem;
  background-color: #ff3d00;
  border-radius: 15px;
}

#vecteurBox1 {
  background-image: url("../../../assets/stats1.svg");
  background-repeat: no-repeat;
  width: 100%;
}

#vecteurBox2 {
  background-image: url("../../../assets/stats2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

#vecteurBox3 {
  background-image: url("../../../assets/stats3.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.statPresenterButton {
  background-color: white;
  border: none;
  color: black;
  min-width: 5rem;
  position: relative;
  top: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}
