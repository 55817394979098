.TabsHeader {
  padding: 1.5rem;
  width: fit-content;
  width: -moz-fit-content;
  cursor: pointer;
}

.TabsHeaderActive {
  padding: 1.5rem;
  width: fit-content;
  width: -moz-fit-content;
  border-bottom: 1px solid #ff3d00;
  cursor: pointer;
  color: #ff3d00;
}

.TabsItem {
  display: none;
}

.TabsItemActive {
  display: block;
}
/* 
.inputContainer {
  display: flex;
  flex-direction: column;
} */

.input {
  padding: 0.75rem;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
  width: 90%;
  margin-top: 0.25rem;
}

.profileImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #8d98aa;
  width: 80%;
  height: 100%;
  margin-left: 3rem;
  margin-top: 2rem;
  cursor: pointer;
}

.inputProfile {
}

.DashboardContent {
  flex: 1; /* Le contenu doit remplir tout l'espace disponible restant */
  overflow-y: auto; /* Ajoute une barre de défilement si nécessaire */
  padding: 20px; /* Ajoute un peu d'espacement autour du contenu */
}