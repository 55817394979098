

#root {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
  }

  .dragging {
    border: 3px groove #ff3d3359;
    background-color: #ddd5f492;
    border-radius: 5px;
  }