.SideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 0 0 auto; /* La sidebar ne doit pas grandir ni rétrécir */
/*   width: 200px; Largeur de la sidebar */
   /*background-color: lightgray; Couleur de fond de la sidebar */
  overflow-y: auto; /* Ajoute une barre de défilement si nécessaire */
  height: 100vh; /* Hauteur de la sidebar */
}
.menuItem {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  /* gap: 0.25rem; */
  font-size: 0.75rem;
}

.menuItem:hover {
  background-color: #ffece6;
}
.menuTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 2rem;
}

.menuSelected {
  color: #ffffff;
  background-color: #ff3d00;
}

.menuSelected:hover {
  background-color: #ff3d00;
}
.selected {
  margin-left: 4rem;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.profile {
  margin-left: 3rem;
  display: flex;
  gap: 0.5rem;
}

.logoutButton {
  margin: 2rem 0rem;
  margin-left: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  background-color: #ffece6;
  color: #ff3d00;
  height: 2rem;
  width: 90%;
  border-radius: 0.5rem;
}
