@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css?family=Montserrat');
 @import url('https://fonts.googleapis.com/css?family=Manrope:@200;300;400;600;700');
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Manrope", "Montserrat", "Inter", sans-serif;
  overflow: hidden;
}
