.TopBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.notifications {
  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications:hover {
  cursor: pointer;
  background-color: #eeeee4;
}

.TopBarRight {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.notificationsContainer {
  position: absolute;
  top: 4rem;
  right: 12rem;
  width: 17rem;
  max-height: 20rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  z-index: 1;
}

.notificationsContainer::-webkit-scrollbar {
  width: 0.5rem;
}

.notificationsHeader {
  display: flex;
  justify-content: space-between;
}

.notif {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
}

.notifMessage {
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
