.table-wrapper {
  margin-top: 0.5rem;
  border-radius: 10px 10px 0 0; /* Apply border-radius to top corners */
  height: 100%;
}

.tablefeature {
  background-color: white;
  border-radius: 10px 10px 0 0;
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapse the borders of the table cells */
}

thead {
  background-color: #7da2d3;
}

th {
  padding: 1rem 0;
  padding-left: 3rem;
  width: 20%;
}

td {
  padding: 0.75rem 0;
  padding-left: 3rem;
}

tbody {
  max-width: 100%;
}

tbody tr {
  height: 3.8rem;
  border-bottom: 1px solid #e0e0e0;
}

.filtersContainer {
  position: absolute;
  z-index: 1;
}

.filterMessage {
  cursor: pointer;
}

#filterSelect {
  cursor: pointer;
}
